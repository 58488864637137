<section class="py-4">
    <div class="container">
        <div class="row">
          <div class="col-12">
              <h1>{{title}}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-responsive">
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th *ngFor="let header of headers">{{ header }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of rows; let first=first">
                    <td *ngFor="let col of row" [ngClass]="{'d-none': first}">{{ col }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </div>
</section>
