<div class="container">
    <section class="py-4">
        <h1>Champions and Reserves</h1>
        <table class="table table-striped table-hover table-responsive">
            <thead>
                <tr>
                    <th>Division</th>
                    <th>Champion</th>
                    <th>Reserve</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let division of divisiones">
                    <tr *ngIf="division.champions[0]">
                        <td>
                           <b>{{division.name}}</b> 
                        </td>
                        <td *ngFor="let champion of division.champions">
                            <div> {{champion.rider}}</div>
                            <strong> {{champion.entry}}</strong>
                            {{champion.horse}}

                        </td>
                        <td>
                            <ng-container *ngFor="let reserve of division.reserves">
                                <div> {{reserve.rider}}</div>
                                <strong> {{reserve.entry}}</strong>
                                {{reserve.horse}}
                                <p>
                            </ng-container>

                        </td>
                    </tr>
                </ng-container>

            </tbody>
        </table>

    </section>
</div>