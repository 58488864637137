<head>
  <title>{{horse.horseName}} - Show Jumping Horse</title>
  <meta itemprop="name" content="horse.horseName - Show Jumping Horse">
  <meta itemprop="description" name="description"
    content="Equestrian Digital profile for horse.horseName. Latest results and stats. ">
  <meta itemprop="image" content="https://equestrian.digital/assets/images/logo-ed.png">
</head>


<div class="container">
  <div class="card mt-4">
    <div class="card-body" style="padding: 0 1rem;">
      <h1>{{horse.horseName}}</h1>
      <div class="row">
        <div class="col-xl-6 pictureContainer">
          <div class="statsSection">
            <table class="mt-2">
              <tbody>
                <tr>
                  <th scope="row">
                    <span class="text">Date of birth</span>
                  </th>
                  <td>{{horse.dob}}</td>
                </tr>
                <tr>
                  <th scope="row">
                    <span class="text">Sire</span>
                  </th>
                  <td>{{horse.sire}}</td>
                </tr>
                <tr>
                  <th scope="row">
                    <span class="text">Dam</span>
                  </th>
                  <td>{{horse.dam}}</td>
                </tr>
                <tr>
                  <th scope="row">
                    <span class="text">Sire of Dam</span>
                  </th>
                  <td>{{horse.sireOfDam}}</td>
                </tr>
                <tr>
                  <th scope="row">
                    <span class="text">Place of birth</span>
                  </th>
                  <td>{{horse.country}}</td>
                </tr>
                <tr>
                  <th scope="row">
                    <span class="text">StudBook</span>
                  </th>
                  <td>{{horse.studbook || horse.breed}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-xl-6 statsSection">
          <table class="mt-2">
            <tbody>
              <tr>
                <th scope="row">
                  <span class="text">Wins</span>
                </th>
                <td>{{wins}} </td>
                <td style="min-width: 70px; font-weight: bold;"> {{(wins / classesEntered) | percent }}</td>
              </tr>
              <tr>
                <th scope="row">
                  <span class="text">Podiums</span>
                </th>
                <td>{{podiums}}</td>
                <td style="min-width: 70px; font-weight: bold;"> {{(podiums / classesEntered) | percent }}</td>
              </tr>
              <tr>
                <th scope="row">
                  <span class="text">Classes Entered</span>
                </th>
                <td>{{classesEntered}}</td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">
                  <span class="text">FEI Id</span>
                </th>
                <td>{{horse.fei}}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Latest Shows -->

        <div class="row mt-2 classLink">
          <div class="col-xl-3" *ngFor="let result of results | slice:0:4; let i=index">
            <a href="/competition/{{result.showId}}/{{result.competitionId}}">
              <img src="{{result.showBanner}}" alt="">
              <div class="showLabel">
                {{result.rank}} - {{result.showName}}
              </div>
              <div class="classHeight">
                {{result.height}} - {{result.className}}
              </div>
              <div class="showDate">
                {{result.date }}
              </div>
            </a>

          </div>


        </div>

      </div>

    </div>


    <!-- Results and Horses -->
    <div class="row">

      <div class="col-xl-6 col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="header-title mb-4">Latest Results</h4>
            <ng-container *ngFor="let result of results">
              <a href='/competition/{{result.showId}}/{{result.competitionId}}' class="d-flex resultRow mb-2">
                <img class="me-3 rounded-circle mr-2 mt-2" src="{{result.clubLogo}}" width="50"
                  style="max-height: 50px;">
                <div class="w-100 overflow-hidden">
                  <span class="badge float-right"
                    [ngClass]="{'badge-warning-lighten': result.rank == 1, 'badge-success-lighten': result.rank < 11, 'badge-info-lighten': result.rank > 10}">Rank:
                    {{result.rank}}</span>
                  <h5>{{result.horse}}</h5>
                  <h5 class="mt-0 mb-1">{{result.number}} - {{result.className}}</h5>
                  <span class="font-13">{{result.showName}}</span> -
                  <span class="font-13">{{result.date}}</span>
                </div>
              </a>
            </ng-container>

          </div>
          <!-- end card-body -->
        </div>
        <!-- end card-->
      </div>
      <!-- end col -->

      <div class="col-xl-6 col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="header-title mb-4">Riders</h4>
            <ng-container *ngFor="let rider of riders">
              <a href='/rider-profile/{{rider.id}}' class="d-flex resultRow mb-2">
                <!-- <a href='' class="d-flex resultRow mb-2"> -->
                <div class="w-100 overflow-hidden">
                  <h4>
                    {{rider.name}}
                  </h4>
                  <!-- <span *ngIf="rider.studbook != '' || rider.breed" class="badge float-right badge-success-lighten">
                    Studbook: {{rider.studbook || rider.breed}}</span> -->
                  <!-- <span *ngIf=" !horse.studbook " class="badge float-right badge-success-lighten">
                  Breed: {{horse.studbook}}</span> -->
                  <!-- <h5>{{rider.color}} {{rider.gender}} <ng-container *ngIf="rider.sire">by {{rider.sire}}</ng-container>
                  </h5> -->

                </div>
              </a>


            </ng-container>
          </div>
        </div>
      </div>

    </div>
  </div>
