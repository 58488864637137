<section class="py-4">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h1>Championships in Show</h1>

                <div *ngFor="let championship of championships" class="mt-2">
                   <!-- <a href="/championship/{{showId}}/{{championship.id}}"> {{championship.name}}</a> -->
                   <div class="btn btn-primary" style="background-color: #12498a;" (click)="goToChampionship(championship.id)">{{championship.name}}</div>
                </div>
            </div>
        </div>
    </div>
</section>