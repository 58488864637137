<footer class="bg-dark py-5" style="background-color: #1C1E22!important">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <img src="assets/images/logo-ed.png" alt="" class="logo-dark" height="40" />
        <span style="margin-left: 5px; font-weight: bold; color: white;">Equestrian Digital</span>
        <p class="text-white-50 mt-4">ED helps to simplify, manage and communicate everything around the
          show. Everyone can stay better informed and have a more streamlined experience in all the show stages. Be it
          online entries, scheduling, checking results or
          check-out, everyone knows exactly what’s going on at all times.
        </p>

        <ul class="social-list list-inline mt-3">
          <li class="list-inline-item text-center">
            <a href="https://www.facebook.com/EcuestreDigital/" target="_blank"
              class="social-list-item border-primary text-primary"><i class="mdi mdi-facebook"></i></a>
          </li>
          <li class="list-inline-item text-center">
            <a href="https://www.youtube.com/c/EcuestreDigital" target="_blank"
              class="social-list-item border-danger text-danger"><i class="mdi mdi-youtube"></i></a>
          </li>
          <li class="list-inline-item text-center">
            <a href="https://www.instagram.com/ecuestredigital/" target="_blank"
              class="social-list-item border-info text-info"><i class="mdi mdi-instagram"></i></a>
          </li>

        </ul>

      </div>

      <!-- <div class="col-lg-2 mt-3 mt-lg-0">
        <h5 class="text-white">Company</h5>

        <ul class="list-unstyled pl-0 mb-0 mt-3">
          <li class="mt-2"><a href="javascript: void(0);" class="text-white-50">About Us</a></li>
          <li class="mt-2"><a href="javascript: void(0);" class="text-white-50">Documentation</a></li>
          <li class="mt-2"><a href="javascript: void(0);" class="text-white-50">Blog</a></li>
          <li class="mt-2"><a href="javascript: void(0);" class="text-white-50">Affiliate Program</a></li>
        </ul>

      </div>

      <div class="col-lg-2 mt-3 mt-lg-0">
        <h5 class="text-white">Apps</h5>

        <ul class="list-unstyled pl-0 mb-0 mt-3">
          <li class="mt-2"><a href="javascript: void(0);" class="text-white-50">Ecommerce Pages</a></li>
          <li class="mt-2"><a href="javascript: void(0);" class="text-white-50">Email</a></li>
          <li class="mt-2"><a href="javascript: void(0);" class="text-white-50">Social Feed</a></li>
          <li class="mt-2"><a href="javascript: void(0);" class="text-white-50">Projects</a></li>
          <li class="mt-2"><a href="javascript: void(0);" class="text-white-50">Tasks Management</a></li>
        </ul>
      </div> -->

      <div class="col-lg-2 offset-lg-4 mt-3 mt-lg-0 ">
        <h5 class="text-white">Links</h5>

        <ul class="list-unstyled pl-0 mb-0 mt-3">
          <!-- <li class="mt-2"><a href="javascript: void(0);" class="text-white-50">Help Center</a></li>
          <li class="mt-2"><a href="javascript: void(0);" class="text-white-50">Our Products</a></li> -->
          <li class="mt-2"><a href="/privacy-policy" class="text-white-50">Privacy Policy</a></li>
          <li class="mt-2"><a href="mailto:hello@equestrian.digital?subject=Contact from ED Website" class="text-white-50">hello@equestrian.digital</a></li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="mt-5">
          <p class="text-white-50 mt-4 text-center mb-0">© 2016 - {{year}} Equestrian Digital </p>
        </div>
      </div>
    </div>
  </div>
</footer>
