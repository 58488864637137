import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ChampionshipService } from './../services/championship.service';
declare var $: any;

@Component({
  selector: 'app-championship',
  templateUrl: './championship.component.html',
  styleUrls: ['./championship.component.scss']
})
export class ChampionshipComponent implements OnInit {
  public spreadsheetId: string;
  public api_key: string;
  public rows: any[];
  public headers: any[];
  public title: string
  public range: string;

  constructor(private db: AngularFirestore, private route: ActivatedRoute, private championshipService: ChampionshipService) {
    this.spreadsheetId = this.route.snapshot.paramMap.get('spreadsheetId');
    this.range = 'J:Q';
    this.api_key = 'AIzaSyDq5-nkgkIEUg2OtUaktaejIqwOZiPzHkI';
    this.rows = [];
    this.title = '';
    this.headers = [];
  }

  ngOnInit(): void {
    this.getSpreadsheetInfo();
  }

  private async getSpreadsheetInfo() {
    const { properties, sheets, error } = await this.championshipService.getSpreadsheetInfo(this.api_key, this.spreadsheetId);
    if(error) return $.NotificationApp.send('Error', error, 'bottom-right', 'rgba(0,0,0,0.2)', 'error');
    const [sheet] = sheets.map(sheet => ({ id: sheet.properties.sheetId, name: sheet.properties.title }));
    this.title = properties.title;
    console.log(error);
    console.log(properties);
    console.log(sheets);
    console.log(sheet);

    const [ headers, ...rows ] = await this.championshipService.getSpreadsheetData(this.api_key, this.spreadsheetId, sheet.name, this.range);
    this.rows = rows;
    this.headers = headers;
    console.log('rows', this.rows);
    console.log('headers', this.headers);
  }
W
}
