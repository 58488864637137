import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChampionshipService } from '../services/championship.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-championships',
  templateUrl: './championships.component.html',
  styleUrls: ['./championships.component.scss']
})
export class ChampionshipsComponent implements OnInit {
  public showId: any;
  public championships: any;

  constructor(private route: ActivatedRoute, private router: Router, private championshipService: ChampionshipService) {
    this.showId = this.route.snapshot.paramMap.get('showId');
    this.championships = [];
  }

  ngOnInit(): void {
    this.getChampionships();
  }

  private async getChampionships() {
    this.championships = await this.championshipService.getChampionships(this.showId);
  }

  goToChampionship(id) {
    this.router.navigate([`/championship`, id]);
  }

}
