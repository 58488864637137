<section class="py-4">
  <div class="container" *ngIf="show">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <img class="card-img-top" [src]="show.banner" alt="Show Banner">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-8">
                <h1 class="card-title">{{show.nombre}}</h1>
                <h4>{{show.inicio | date:'dd/MMM' }} - {{show.fin | date:'dd/MMM'}}</h4>

                <h5>{{show.lugar}}</h5>
                <a href="/masterlist/{{show.id}}">
                  <div class="btn btn-dark">Masterlist</div>
                </a>
                <a href="//entries.equestrian.digital" class="ml-2" target="_blank" *ngIf="show.inscripcionesOnline && show.zona == 'usa'">
                  <div class="btn btn-dark">Online Entries</div>
                </a>
                <a href="/championships/{{show.id}}" class="ml-2" target="_blank" *ngIf="show.id == 'us_495'">
                  <div class="btn btn-dark">Championships</div>
                </a>
                <a href="{{show.buttonLink}}" class="ml-2" target="_blank">
                  <div class="btn btn-dark">{{show.buttonTitle}}</div>
                </a>

                <div *ngIf="show.message">Mensaje: {{show.message}}</div>
              </div>
              <div class="col-xl-4 text-right">
                <img [src]="show.logo_url" alt="Organizer Logo" style="max-height:100px;">
              </div>
            </div>
            <div class="row">
              <ul class="list-unstyled mt-1">
                <li *ngFor="let announcement of announcements" class="mb-4">
                  <h5 class="text-infofw-bold mb-1 d-block"><i
                      class="uil uil-comment-message font-16 me-1"></i>{{announcement.title}}</h5>
                  <p class="text-muted mb-1 font-13">{{announcement.description}}</p>
                </li>
              </ul>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">

          <div class="btn-group">
            <ng-container *ngFor="let classDay of show.dias">
              <button [ngClass]="{active: day == classDay }" (click)="setDay(classDay)"
                class="button btn btn-dark dayButton" style="flex: 1; height: 50px;" id="{{classDay}}">{{classDay |
                date}}</button>
            </ng-container>


          </div>


        </div>

      </div>
    </div>
    <div class=" row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-nowrap table-hover">
                <tbody>
                  <tr *ngFor="let competition of getFilteredCompetitions()" href="#" class="classRow">
                    <ng-container *ngIf="competition.tipo == 'pista'">
                      <td colspan="3">
                        <h3>
                          {{competition.nombre.toUpperCase()}}
                        </h3>
                      </td>
                    </ng-container>
                    <ng-container *ngIf="competition.tipo == 'prueba'">
                      <td class="classTime">
                        <span *ngIf="competition.hard_start == 1">
                          {{competition.inicio.toMillis() | date:'h:mm a'}}
                        </span>


                      </td>

                      <td class="classInfo">
                        <a href="/competition/{{showId}}/{{competition.ipc}}">
                          <h5 class="className font-15 my-1 fw-normal">{{competition.numero}} - {{competition.nombre}}
                          </h5>
                        </a>

                        <div class="classType text-muted font-13">
                          <span style="float: left;">{{competition.clave_modalidad}} </span>
                          <span class="d-none d-lg-block" style="float: left;"> -
                            {{competition.nombre_modalidad}}</span>
                        </div>
                        <br>

                        <div class="classProgress">{{competition.calificados}} / {{competition.participantes}}</div>
                      </td>
                      <td class="classStatus">
                        <span *ngIf="competition.estatus_prueba == 0">
                          <i class="mdi mdi-circle" style="font-size: 2em;"></i>
                        </span>
                        <span *ngIf="competition.estatus_prueba == 1">
                          <i class="mdi mdi-circle text-success" style="font-size: 2em;"></i>

                        </span>
                        <span *ngIf="competition.estatus_prueba == 2">
                          <i class="mdi mdi-trophy text-warning" style="font-size: 2em;"></i>
                        </span>
                        <span *ngIf="competition.youtube_url">
                          <i class="mdi mdi-youtube text-danger" style="font-size: 2.5em; margin-left: 5px;"></i>
                        </span>
                      </td>
                    </ng-container>






                  </tr>
                </tbody>

              </table>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</section>
