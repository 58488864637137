import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  filtro: any;
  mostrarMax: number = 20;
  jinetes: any;
  jinetesMostrar: any;
  caballos: any;
  caballosMostrar: any;

  myHeaders = new Headers();
  
  constructor(private route: Router) { 

  }

  ngOnInit() {
    this.myHeaders.append("x-api-key", "7cc263a1-a490-4337-8302-151490495e56");
    this.myHeaders.append("Content-Type", "application/json");
  }

  onSearchRidersKeyUp(event: any) {
    //console.log('caracteres: ' + event.target.value.length)
    if (event.target.value.length >= 3) {
      this.filtro = event.target.value;

      //let url = 'https://api2.ecuestre.digital/jinetes/query/';
      let url = 'https://nest.ecuestre.digital/v1/personas/jinetes';


      var raw = JSON.stringify({
        "query": this.filtro
       // "idConcurso": "1"
      });

      var requestOptions: any = {
        method: 'POST',
        headers: this.myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(url, requestOptions)
        .then(response => response.json())
        .then(result => {
          this.jinetes = result.data;
          this.jinetesMostrar = this.jinetes.slice(0, this.mostrarMax);
          this.jinetes = null;
          //console.log('Jinetes Encontrados', this.jinetes);
        })

        .catch(error => console.log('error', error));
    }
    else if (event.target.value.length == 0) {
      this.jinetesMostrar = null;
    }

  }

  onSearchHorsesKeyUp(event: any) {
    //console.log('caracteres: ' + event.target.value.length)
    if (event.target.value.length >= 3) {
      this.filtro = event.target.value;

      let url = 'https://api2.ecuestre.digital/caballos/query/';

      var raw = JSON.stringify({
        "filtro": this.filtro,
        "idConcurso": "1"
      });

      var requestOptions: any = {
        method: 'POST',
        headers: this.myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(url, requestOptions)
        .then(response => response.json())
        .then(result => {
          this.caballos = result;
          this.caballosMostrar = this.caballos.slice(0, this.mostrarMax);
          this.caballos = null;
          //console.log('caballos Encontrados', this.caballos);
        })

        .catch(error => console.log('error', error));
    }
    else if (event.target.value.length == 0) {
      this.caballosMostrar = null;
    }

  }

  viewRider(id_jinete: string) {
    this.jinetesMostrar = null;
    document.getElementById('search_input_riders').innerText = '';

    //this.route.navigate(['/rider-profile/'+ id_jinete]);
    this.updatePage('/rider-profile/'+ id_jinete);
  }

  viewHorse(id_caballo: string) {
    this.caballosMostrar = null;
    document.getElementById('search_input_horses').innerText = '';

    //this.route.navigate(['/horse-profile/'+ id_caballo]);
    this.updatePage('/horse-profile/'+ id_caballo);
  }

  updatePage(uri:string){
    this.route.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.route.navigate([uri]));
  }

}
