<section class="py-4">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1>List of Entries for Show</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table table-striped table-responsive">
          <thead>
            <tr>
              <th>Entry</th>
              <th>Horse Name</th>
              <th>Rider</th>
              <!-- <th>Trainer</th> -->
            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let entry of entries">
              <td scope="row"><a href="/entry/{{showId}}/{{entry.dorsal}}">{{entry.dorsal}}</a></td>
              <td><a href="/entry/{{showId}}/{{entry.dorsal}}">{{entry.caballo}}</a> </td>
              <td><a href="/entry/{{showId}}/{{entry.dorsal}}">{{entry.jinete}}</a></td>
              <!-- <td>{{entry | json}}</td> -->
            </tr>
          </tbody>
        </table>


      </div>

    </div>

  </div>
</section>
