import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-masterlist',
  templateUrl: './masterlist.component.html',
  styleUrls: ['./masterlist.component.scss']
})
export class MasterlistComponent implements OnInit {
  public showId: string;
  public entries: Array<any>;
  public showPrefix: string = '';

  constructor(private db: AngularFirestore, private route: ActivatedRoute) {
    this.showId = this.route.snapshot.paramMap.get('showId');
    if (this.showId.includes('us_')) {
      this.showPrefix = 'us_';
    }

    this.entries = [];
  }

  ngOnInit(): void {
    this.getEntries();
  }

  private getEntries() {
    this.db.collection('concursos').doc(this.showId).collection('masterlist', ref => ref.orderBy('dorsal')).valueChanges().subscribe(
      entries => {
        this.entries = entries;
      }
    );
  }

}
