<div class="container">
  <div class="card mt-4">
    <div class="card-body" style="padding: 0 1rem;" *ngIf="personInfo">
      <h1>
        <img [src]="personInfo.pais?.bandera_url" alt="" class="flag hide-me">
        {{ personInfo?.name}} {{ personInfo?.lastname_1 }}
       
      </h1>
      <div class="row hide">
        <img [src]="personInfo.pais?.bandera_url" alt="" class="flag">
        <span class="upper country"> {{ personInfo.pais?.siglas }} </span>
      </div>
      <hr class="m-0">
      <div class="row">
        <div class="col-xs-12 col-md-5 trainerInfo">
          <table class="w-100">
            <tbody>
              <tr>
                <td class="upper text-left bold">City, State</td>
                <td class="text-right">{{ personInfo.ciudad}} , {{ personInfo.estado }}</td>
              </tr>
              <tr>
                <td class="upper text-left bold">Status</td>
                <td class="text-right">{{personInfo.categoria}}</td>
              </tr>
             
            </tbody>
          </table>
        </div>
        <div class="col-xs-12 col-md-5 offset-md-1 trainerInfo">
          <table class="w-100">
            <tbody>
              <tr>
                <td class="upper text-left bold">USEF ID</td>
                <td class="text-right">{{personInfo.usef_id}}</td>
              </tr>
              <tr>
                <td class="upper text-left bold">USHJA ID</td>
                <td class="text-right">{{personInfo.ushja_id}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <table class="table table-sm">
          <thead>
            <th>Entry</th>            
            <th class="">Rider/Horse</th>
            <th>Class</th>
            <!-- <th class="hide-mobile">Count</th>
            <th class="hide-mobile">Progress</th> -->
            <th class="hide-mobile">Rank</th>
          </thead>
          <tbody>
            <ng-container *ngFor="let entry of entries">
              <tr *ngFor="let result of entry.resultados">
                <td class=""> {{ entry.numero }} </td>
                <td>
                  <a [routerLink]="['/profile', entry.jinete.id_persona, showId]" routerLinkActive="router-link-active">
                    <img [src]="entry.jinete.foto_url" alt="" class="profile-pic">
                    {{ entry.jinete.name | titlecase}} {{ entry.jinete.lastname_1 }} 
                  </a>
                  <br>
                  <a [routerLink]="['/horse-profile', 'us_'+entry.caballo.id_caballo]" routerLinkActive="router-link-active">
                   <strong style="font-size: .9em;">{{ entry.caballo.fullname }} </strong>
                  </a>
                 
                </td>
                
                <td class="hide-mobile">
                  <a [routerLink]="['/competition', 'us_'+showId, 'us_'+result.prueba.ipc]" routerLinkActive="router-link-active">
                   {{result.prueba.numero}} - {{ result.prueba.fullname | titlecase}} 
                  </a>
                  <div>
                    <i class="mdi mdi-calendar"></i>
                    {{result.prueba.inicio | date: 'EE MMM, d' }}
                  </div>
                  <div style="font-size: .8em;">
                    <i class="mdi mdi-map-marker-radius"></i>
                    {{result.prueba.pista}}</div>

                  
                </td>
               
                <!-- <td class="hide-mobile">
                  <a [routerLink]="['/competition', showId, entry.ipc]" routerLinkActive="router-link-active">
                    {{ entry.total_entries }} 
                  </a>
                </td> -->
                <!-- <td class="hide-mobile">
                  <a [routerLink]="['/competition', showId, entry.ipc]" routerLinkActive="router-link-active">
                    {{ entry.total_scored / entry.total_entries * 100 | number: '1.0-0' }}% <br>
                    <span *ngIf="entry.total_scored > 0">{{ entry.total_scored }} / {{ entry.total_entries }}</span>
                  </a>
                </td> -->
                <td>
                  <p class="hide upper black" [routerLink]="['/competition', 'us_'+showId, 'us_'+result.prueba.ipc]" routerLinkActive="router-link-active">
                    <strong>{{ entry.class }}</strong>
                  </p>
                  <p class="hide"> 
                    <i class="mdi mdi-location-pin"></i>
                    {{ result.prueba.pista }} <br>
                    <!-- Count: {{ entry.total_entries }} <br>
                    Progress: {{ entry.total_scored / entry.total_entries * 100 | number: '1.0-0' }}% <br>
                    <span *ngIf="entry.total_scored > 0">{{ entry.total_scored }} / {{ entry.total_entries }}</span> -->
                  </p>
                  <div [ngStyle]="{
                    'color': result.posicion == 1 ? '#007bd9' :
                              result.posicion == 2 ? 'red' :
                              result.posicion == 3 ? '#f9bc0d' :
                              'green'
                  }" >
                    <span style="float: left;" class="mr-1" *ngIf="result.posicion && result.prueba.estatus == 2">
                      <i class="fa-solid fa-award"></i>
                    </span>
                    <span style="float: left">  {{ result.posicion ?? '-' }}</span>
                  </div>
                 
                 
                  <!-- <p *ngIf="entry.placing == ''"> Order of go: {{ entry.order_of_go }} </p> -->
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="m-4  text-center">
        NOTE: This lists all the results linked to this person either as a rider or a trainer.
      </div>
    </div>
  </div>
</div>
