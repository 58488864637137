import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { EntryClassesService } from '../services/entry-classes.service';

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss']
})
export class EntryComponent implements OnInit {
  public showId: string;
  public entry: string;
  public entryInfo: any;
  public classes: Array<any>;
  public rider: string;

  constructor(private db: AngularFirestore, private route: ActivatedRoute, private entryClassesService: EntryClassesService,) {
    this.showId = this.route.snapshot.paramMap.get('showId');
    this.entry = this.route.snapshot.paramMap.get('entryId');
  }

  ngOnInit(): void {
    if(this.showId) {
      this.getEntryInfo();
      this.getEntryClasses();
    }
  }

  private getEntryInfo() {
    this.db.collection('concursos').doc(this.showId).collection('masterlist', ref => ref.where('dorsal', '==', parseInt(this.entry))).get().subscribe(snapshot => {
      const entries = snapshot.docs.map(entry => entry.data());
      //Usar el primer entry del arreglo
      const [entry] = entries;
      this.entryInfo = entry||{};
      console.log('Entry Info: ', this.entryInfo);
    });
  }

  private getEntryClasses() {
    console.log('Getting Classes');
    this.entryClassesService.getEntryClasses(this.entry, this.showId).subscribe(
      response => {
        console.log('Response: ', response);
        //this.rider = response[0].nombre;
        this.classes = response.classes;
        console.log('Response Classes: ', this.classes);
      }
    );
  }
}
